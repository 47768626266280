import { YieldDescription } from '@common/interfaces/YieldDescription';
import { formatYieldDescriptionOrUndefined } from '@common/logic/formatYieldDescription';

import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import CopyAll from '@mui/icons-material/ContentCopy';
import Email from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { isNil } from 'ramda';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { OpenWhatsAppError } from '../../../whatsapp-open-chat/errors/OpenWhatsAppError';
import { whatsAppService } from '../../../whatsapp-open-chat/services/WhatsAppOpenChatService';
import { useAdvisoryOfficeId } from '../../hooks/useAdvisoryOfficeId';
import { VisualizationContext } from '../../pages/DealsPage/VisualizationContext';
import { MaybeNil } from '../DisplayDeal/logic/MaybeNil';
import { formatDateOrUndefined } from '../DisplayDeal/logic/formatDateOrUndefined';
import { EditDealContext } from '../EditDeal/EditDealContext';
import { clipboardService } from './ClipboardService';
import { CreateOrUpdateCustomPitchText } from './CreateOrUpdateCustomPitchText';
import { emailSenderService } from './EmailSenderService';
import { getDomainUrl } from './logic/getDomainUrl';
import { getEmailText } from './logic/getEmailText';
import { getWhatsAppText } from './logic/getWhatsAppText';
import { valueOrLoader } from './valueOrLoader';

export interface IDisplayDealPitchProps {
  customPitch: MaybeNil<{
    text: string;
  }>;
  fixedIncomePosition: MaybeNil<{
    id: string;
    nickname: string;
    maturityDate: Date;
    yieldDescription: YieldDescription;
    appliedTotalPrice: number;
    ticker?: MaybeNil<{
      rebuyTotalNetPrice?: number | null;
    }>;
  }>;
  fixedIncomeOffer: MaybeNil<{
    id: string;
    nickname: string;
    maturityDate: Date;
    yieldDescription: YieldDescription;
  }>;
  dealFinancials: MaybeNil<{
    customerMarkUp?: MaybeNil<number>;
    substituteFixedIncomeOfferPriceApplied: number;
  }>;
  onSuccessCreateOrUpdateCustomPitchText: () => void;
}

export const DisplayDealPitch: React.FC<IDisplayDealPitchProps> = ({
  customPitch,
  fixedIncomePosition,
  fixedIncomeOffer,
  dealFinancials,
  onSuccessCreateOrUpdateCustomPitchText,
}) => {
  const { hideSensitiveInformation, isPublic } =
    VisualizationContext.useContainer();

  const { duration } = EditDealContext.useContainer();

  const { officeId } = useAdvisoryOfficeId();

  const myRef = useRef<HTMLDivElement>(null);

  const getParams = () => {
    if (
      !fixedIncomeOffer ||
      !fixedIncomePosition ||
      !dealFinancials ||
      isNil(dealFinancials.customerMarkUp)
    ) {
      return null;
    }
    return {
      name: 'investidor',
      customPitch,
      position: {
        maturityDate: fixedIncomePosition.maturityDate,
        nickName: fixedIncomePosition.nickname,
        appliedTotalPrice: fixedIncomePosition.appliedTotalPrice,
        rebuyTotalNetPrice: fixedIncomePosition.ticker?.rebuyTotalNetPrice ?? 0,
        yieldDescription: fixedIncomePosition.yieldDescription,
      },
      offer: {
        maturityDate: fixedIncomeOffer.maturityDate,
        nickName: fixedIncomeOffer.nickname,
        appliedTotalPrice:
          dealFinancials.substituteFixedIncomeOfferPriceApplied,
        yieldDescription: fixedIncomeOffer.yieldDescription,
      },
      financials: {
        markup: dealFinancials.customerMarkUp,
      },
    };
  };

  const handleWhatsAppClick = (defaultInput = ''): void => {
    const params = getParams();
    if (!params) {
      return;
    }
    const text = getWhatsAppText(params);

    const targetPhone = prompt(
      'Número que irá receber (ex: 119123456789)',
      defaultInput,
    );
    if (isNil(targetPhone)) {
      return;
    }
    try {
      whatsAppService.openChat(targetPhone, text);
    } catch (err) {
      if (err instanceof OpenWhatsAppError) {
        if (err.message === 'NO_DDD') {
          alert('Por favor insira o DDD');
          handleWhatsAppClick(err.phone);
        } else if (err.message === 'INVALID_NUMBER') {
          alert('Número inválido');
          handleWhatsAppClick(err.phone);
        }
      } else if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  const handleSendEmailClick = () => {
    try {
      const to = prompt('E-mail do destinatário (Opcional)') ?? '';
      const subject = 'Operação de Renda Fixa';
      const params = getParams();
      if (!params) {
        return;
      }
      const body = getEmailText(params);
      emailSenderService.sendEmail({
        to,
        subject,
        body,
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  const getDurationQueryParam = () => {
    if (duration) {
      return `&d=${encodeURIComponent(duration.toFixed(2))}`;
    }
    return '';
  };

  const handleCopyPublicUrlClick = (hideSensitiveInformation: boolean) => {
    try {
      if (!fixedIncomeOffer || !fixedIncomePosition || !officeId) {
        return;
      }
      const path = hideSensitiveInformation ? 'c' : 'p';
      const url = `${getDomainUrl()}/${path}/negociacao/${fixedIncomePosition.id}/${fixedIncomeOffer.id}?o=${officeId}${getDurationQueryParam()}`;
      clipboardService.copyTextToCliboard(url);
      toast.success('URL copiada para área de transferência (Ctrl+C)');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  const handleCopyToClipboardClick = () => {
    try {
      const params = getParams();
      if (!params) {
        return;
      }
      clipboardService.copyTextToCliboard(getEmailText(params));
      toast.success('Texto copiado para área de transferência (Ctrl+C)');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  const shouldDisplayCreateOrUpdateCustomPitchText =
    fixedIncomeOffer && fixedIncomePosition && !isPublic;

  return (
    <Box>
      <Paper elevation={5} style={{ padding: 20 }}>
        <div ref={myRef}>
          <Typography variant='overline' gutterBottom>
            Olá Investidor, tudo bem?
          </Typography>
          <Typography variant='caption' component='div'>
            Estamos fazendo um trabalho especializado na carteira de Renda Fixa
            dos nossos clientes, buscando detectar oportunidades de melhorar
            ainda mais a performance da sua carteira.
          </Typography>
          <Typography variant='caption' component='div' gutterBottom>
            Identificamos uma operação interessante que gostaria de compartilhar
            com você.
          </Typography>
          <Typography variant='overline'>
            Resumo da Estratégia de venda:
          </Typography>
          <Typography variant='caption' component='div'>
            Venda do ativo <b>{fixedIncomePosition?.nickname}</b>
          </Typography>
          <Typography variant='caption' component='div'>
            Valor Inicial Aplicado:{' '}
            <b>
              {valueOrLoader(
                formatReaisOrUndefined(fixedIncomePosition?.appliedTotalPrice),
              )}
            </b>
          </Typography>
          <Typography variant='caption' component='div'>
            Vencimento em:{' '}
            <b>
              {valueOrLoader(
                formatDateOrUndefined(fixedIncomePosition?.maturityDate),
              )}
            </b>
          </Typography>
          <Typography variant='caption' component='div'>
            Taxa Contratada:{' '}
            <b>
              {valueOrLoader(
                formatYieldDescriptionOrUndefined(
                  fixedIncomePosition?.yieldDescription,
                ),
              )}
            </b>
          </Typography>

          <Typography variant='caption' component='div' gutterBottom>
            Valor de Resgate:{' '}
            <b>
              {valueOrLoader(
                formatReaisOrUndefined(
                  fixedIncomePosition?.ticker?.rebuyTotalNetPrice,
                ),
              )}
            </b>
          </Typography>
          <Typography variant='overline'>
            Resumo da Estratégia de compra:
          </Typography>
          <Typography variant='caption' component='div'>
            Compra do ativo <b>{fixedIncomeOffer?.nickname}</b>
          </Typography>
          <Typography variant='caption' component='div'>
            Valor Inicial aplicado:{' '}
            <b>
              {valueOrLoader(
                formatReaisOrUndefined(
                  dealFinancials?.substituteFixedIncomeOfferPriceApplied,
                ),
              )}
            </b>
          </Typography>
          <Typography variant='caption' component='div'>
            Novo vencimento em:{' '}
            <b>
              {valueOrLoader(
                formatDateOrUndefined(fixedIncomeOffer?.maturityDate),
              )}
            </b>
          </Typography>
          <Typography variant='caption' component='div'>
            Nova taxa contratada:{' '}
            <b>
              {valueOrLoader(
                formatYieldDescriptionOrUndefined(
                  fixedIncomeOffer?.yieldDescription,
                ),
              )}
            </b>
          </Typography>
          <Typography variant='caption' component='div' gutterBottom>
            Ganho proporcionado:{' '}
            <b>
              {valueOrLoader(
                formatReaisOrUndefined(dealFinancials?.customerMarkUp),
              )}
            </b>
          </Typography>

          <Stack direction='row' columnGap={1} alignItems='center'>
            <Typography variant='overline'>Explicações adicionais</Typography>
            {shouldDisplayCreateOrUpdateCustomPitchText && (
              <CreateOrUpdateCustomPitchText
                currentCustomText={customPitch?.text}
                onSuccess={onSuccessCreateOrUpdateCustomPitchText}
                offerId={fixedIncomeOffer.id}
                positionId={fixedIncomePosition.id}
              />
            )}
          </Stack>

          {customPitch ? (
            <Typography variant='caption' component={'div'} gutterBottom>
              {customPitch.text}
            </Typography>
          ) : (
            <>
              <Typography variant='caption' component={'div'} gutterBottom>
                A operação consiste na venda antecipada de um título de renda
                fixa com baixa rentabilização em sua carteira atual, e
                respectivamente a compra de um ativo com taxa mais atrativa e
                prazo maior (aproveitando a elevada taxa de juros por mais
                tempo).
              </Typography>

              <Typography variant='caption' component={'div'} gutterBottom>
                Esse valor pode oscilar para mais ou menos dependendo do cenário
                da curva de juros futura e inflação. Utilizamos premissas de
                cálculos baseado nas taxas de mercado atualizadas para a
                montagem dessa estratégia.
              </Typography>
              <Typography variant='caption' component={'div'} gutterBottom>
                Caso tenha interesse nessa operação ou queira saber um pouco
                mais, basta me avisar!
              </Typography>
              <Typography variant='caption' component={'div'}>
                Muito obrigado e estamos à disposição.
              </Typography>
            </>
          )}
        </div>
      </Paper>
      {!hideSensitiveInformation && (
        <Grid container rowSpacing={1} sx={{ marginTop: 1 }}>
          <Grid item sm={12}>
            <Button
              variant='outlined'
              startIcon={<WhatsAppIcon />}
              onClick={() => handleWhatsAppClick()}
              fullWidth
            >
              Enviar por WhatsApp
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Button
              variant='outlined'
              startIcon={<Email />}
              onClick={() => handleSendEmailClick()}
              fullWidth
            >
              Enviar por E-mail
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Button
              variant='outlined'
              startIcon={<CopyAll />}
              fullWidth
              onClick={() => handleCopyToClipboardClick()}
            >
              Copiar texto
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Button
              variant='outlined'
              startIcon={<CopyAll />}
              fullWidth
              onClick={() => handleCopyPublicUrlClick(false)}
            >
              Copiar URL compartilhável para Assessor
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Button
              variant='outlined'
              startIcon={<CopyAll />}
              fullWidth
              onClick={() => handleCopyPublicUrlClick(true)}
            >
              Copiar URL compartilhável para Cliente
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

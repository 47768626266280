import { floatTransformer } from '@common/logic/floatTransformer';
import { FormTextField } from '@invest-ai/animatrix';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MaybeNil } from '../DisplayDeal/logic/MaybeNil';
import { EditDealContext } from './EditDealContext';

interface IEditDeal {
  positionDuration: MaybeNil<number>;
  offerDuration: MaybeNil<number>;
  onSubmit: (duration: number) => void;
}

export const EditDeal: React.FC<IEditDeal> = ({
  positionDuration,
  offerDuration,
  onSubmit,
}) => {
  // We want to use raw duration data to calculate, but want to show formatted value on client side
  const [rawDuration, setRawDuration] = useState(0);
  const { visible, toggleVisibility } = EditDealContext.useContainer();

  const methods = useForm<{ duration: number }>({
    mode: 'onChange',
    defaultValues: { duration: 0 },
  });

  const duration = methods.watch('duration');

  const formattedPositionDuration = Number.parseFloat(
    (positionDuration ?? 0).toFixed(2),
  );
  const formattedOfferDuration = Number.parseFloat(
    (offerDuration ?? 0).toFixed(2),
  );

  // If duration is being updated by clicking position/offer button, we don't want to update rawDuration.
  const shouldAvoidRawDurationUpdate = (duration: number) => {
    return [formattedPositionDuration, formattedOfferDuration].includes(
      duration,
    );
  };

  useEffect(() => {
    if (shouldAvoidRawDurationUpdate(duration)) return;
    setRawDuration(duration);
  }, [duration]);

  return (
    <Drawer anchor='right' open={visible} onClose={toggleVisibility}>
      <Box p={4} width={400}>
        <Typography variant='h4' mb={4}>
          Editar simulação
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(() => onSubmit(rawDuration))}>
            <Box display='flex' gap={2} mb={2}>
              {positionDuration && (
                <Button
                  variant='outlined'
                  onClick={() => {
                    methods.setValue('duration', formattedPositionDuration);
                    setRawDuration(positionDuration);
                  }}
                  color='inherit'
                  sx={{ flexGrow: 1 }}
                >
                  Usar posição {`(${formattedPositionDuration})`}
                </Button>
              )}
              {offerDuration && (
                <Button
                  variant='contained'
                  onClick={() => {
                    methods.setValue('duration', formattedOfferDuration);
                    setRawDuration(offerDuration);
                  }}
                  color='inherit'
                  sx={{ flexGrow: 1 }}
                >
                  Usar oferta {`(${formattedOfferDuration})`}
                </Button>
              )}
            </Box>
            <FormTextField
              name='duration'
              control={methods.control}
              label='Duration'
              type='number'
              inputProps={{
                step: '.01',
              }}
              transform={floatTransformer}
              rules={{
                required: true,
                max: 15,
              }}
              sx={{ mb: 3 }}
            />
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='text'
                onClick={toggleVisibility}
                color='inherit'
                sx={{ mr: 2 }}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={!methods.formState.isValid}
              >
                Confirmar
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Drawer>
  );
};

import { parseISO } from 'date-fns';

export const parseServerDate = (str: string) => parseISO(str);
export const safeParseServerDate = (str: Date | string | null | undefined) => {
  if (str instanceof Date) {
    return str;
  }
  if (typeof str === 'string') {
    return parseISO(str);
  }
  return new Date();
};

export const isDateBetween = (
  date: Date | undefined,
  [min, max]: [Date, Date],
) => {
  if (!date) return false;
  return date >= min && date <= max;
};

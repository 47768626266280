import { Button, Color, DisplayError } from '@invest-ai/animatrix';
import CopyAll from '@mui/icons-material/ContentCopy';
import { Alert, Box } from '@mui/material';
import { format } from 'date-fns';
import { useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';

import { safeFormatDate } from '@common/date/formatters';
import ContentLoader from 'react-content-loader';
import { toast } from 'react-toastify';
import { useComparisonCharts } from '../../../hooks/useComparisonCharts';
import { clipboardService } from '../../DealPitch/ClipboardService';
import { EditDealContext } from '../../EditDeal/EditDealContext';
import { COMPARISON_CHART_OPTIONS } from '../constants/COMPARISON_CHART_OPTIONS';

export interface IComparisonChartProps {
  fixedIncomeOfferId: string;
  fixedIncomePositionId: string;
  offerPriceApplied: number;
  positionPriceApplied: number;
}

export const ComparisonChart: React.FC<IComparisonChartProps> = ({
  fixedIncomeOfferId,
  fixedIncomePositionId,
  offerPriceApplied,
  positionPriceApplied,
}) => {
  const chartRef = useRef<any>();

  const { duration } = EditDealContext.useContainer();

  const handleCopyChartClick = () => {
    if (chartRef.current) {
      chartRef.current.canvas.toBlob((blob: any) => {
        if (!blob) {
          return;
        }
        const item = new ClipboardItem({ 'image/png': blob });
        try {
          clipboardService.copyToClipboard([item]);
          toast.success('Gráfico copiado para área de transferência (Ctrl+C)');
        } catch (err) {
          if (err instanceof Error) {
            toast.error(`Erro: ${err.message}`);
            return;
          }
          throw err;
        }
      });
    }
  };

  const {
    loading,
    error,
    fixedIncomePositionChart,
    fixedIncomeOfferChart,
    intersectionPoint,
  } = useComparisonCharts({
    fixedIncomeOfferId,
    fixedIncomePositionId,
    offerPriceApplied,
    positionPriceApplied,
    duration,
  });

  const ds = useMemo(
    () =>
      fixedIncomePositionChart?.dataset.map((point: any) => ({
        x: format(new Date(point.x), 'yyyy-MM-dd'),
        y: point.y,
      })) ?? [],
    [fixedIncomePositionChart],
  );

  const ds2 = useMemo(
    () =>
      fixedIncomeOfferChart?.dataset.map((point: any) => ({
        x: format(new Date(point.x), 'yyyy-MM-dd'),
        y: point.y,
      })) ?? [],
    [fixedIncomeOfferChart],
  );

  if (loading) {
    return (
      <ContentLoader
        display={'block'}
        speed={1}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        width={'100%'}
        height={278}
      >
        <rect x='0' y='0' rx='5' ry='5' width={'100%'} height={278} />
      </ContentLoader>
    );
  }

  if (error) {
    return <DisplayError error={error} />;
  }

  const data = {
    datasets: [
      {
        label: 'Ativo atual',
        data: ds,
        borderColor: '#FFC107',
        backgroundColor: 'rgba(255,225,106, 0.16)',
        fill: true,
      },
      {
        label: 'Novo ativo',
        data: ds2,
        borderColor: Color.POSITIVE,
        backgroundColor: 'rgba(91,229,132, 0.16)',
        fill: true,
      },
    ],
  };

  return (
    <Box>
      <Line options={COMPARISON_CHART_OPTIONS} data={data} ref={chartRef} />
      {intersectionPoint && (
        <Alert sx={{ mb: 1, mt: 1 }} color='warning' variant='outlined'>
          Ponto de intersecção:{' '}
          <strong>{safeFormatDate(intersectionPoint, 'MM/yyyy')}</strong>
        </Alert>
      )}
      <Button
        startIcon={<CopyAll />}
        fullWidth
        variant='soft'
        onClick={handleCopyChartClick}
        sx={{ mb: 1 }}
      >
        Copiar gráfico
      </Button>
    </Box>
  );
};

import { DisplayError } from '@invest-ai/animatrix';
import { useCalculateDealFromRoute } from '../../hooks/useCalculateDealFromRoute';
import { EditDealContext } from '../EditDeal/EditDealContext';
import { DisplayDealPitch } from './DisplayDealPitch';

export const DealPitch: React.FC<{
  offerId: string;
  positionId: string;
}> = ({ offerId, positionId }) => {
  const { duration } = EditDealContext.useContainer();
  const {
    dealFinancials,
    fixedIncomePosition,
    fixedIncomeOffer,
    error,
    customPitch,
    refetch,
  } = useCalculateDealFromRoute({
    offerId,
    positionId,
    duration,
  });

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <DisplayDealPitch
      customPitch={customPitch}
      fixedIncomePosition={fixedIncomePosition}
      fixedIncomeOffer={fixedIncomeOffer}
      dealFinancials={dealFinancials}
      onSuccessCreateOrUpdateCustomPitchText={refetch}
    />
  );
};

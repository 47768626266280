import { NumberColorized } from '@common/components/NumberColorized';
import { safeFormatDate } from '@common/date/formatters';
import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { formatPercentage } from '@invest-ai/common';
import { GridColDef } from '@mui/x-data-grid-premium';
import {
  FixedIncomeOfferFragmentFragment,
  FixedIncomeOfferSource,
} from '../../../../../../../__generated__/types';
import { CustomNicknameCell } from './CustomNicknameCell';
import { EditOfferYieldDescriptionAction } from './EditOfferYieldDescriptionAction';

export const getColumnsToRender = (
  onClickEditOffer: (offerId: string) => void,
): GridColDef<FixedIncomeOfferFragmentFragment>[] => [
  {
    field: 'nickname',
    headerName: 'Ativo',
    minWidth: 250,
    renderCell: (params) => {
      const { nickname, freeOfIR, target, id, source } = params.row;
      return (
        <CustomNicknameCell
          nickname={nickname}
          freeOfIR={freeOfIR}
          investorQualified={target === 'QUALIFIED'}
          offerCreatedByUser={
            source === FixedIncomeOfferSource.CREATED_BY_ADVISOR
          }
          offerId={id}
          onClickEditOffer={onClickEditOffer}
        />
      );
    },
  },
  {
    field: 'productKind',
    width: 100,
    headerName: 'Produto',
  },
  {
    field: 'yieldRate',
    headerName: 'Taxa',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'roa',
    headerName: 'ROA',
    flex: 1,
    valueFormatter: (params) => formatPercentage(params.value),
  },
  {
    field: 'rating',
    headerName: 'Rating',
    flex: 1,
  },
  {
    field: 'maturityDate',
    headerName: 'Vencimento',
    flex: 1,
    valueFormatter: (params) => safeFormatDate(params.value),
  },
  {
    field: 'duration',
    headerName: 'Duration',
    valueFormatter: (params) => params.value?.toFixed(2) ?? '-',
    maxWidth: 50,
  },
  {
    field: 'customerMarkUp',
    headerName: 'Ágio Duration',
    minWidth: 100,
    sortable: true,
    renderCell: (params) => {
      if (params.value) {
        return (
          <NumberColorized
            value={formatReaisOrUndefined(params.value)}
            valueStyle={{ fontSize: 14 }}
          />
        );
      }
      return '-';
    },
  },
  {
    field: 'stretchingInMonths',
    headerName: 'Alongamento',
    sortable: false,
    maxWidth: 100,
    valueFormatter: (params) => {
      if (params.value) {
        return `${params.value} meses`;
      }
      return '-';
    },
  },
  {
    field: 'firstAmortizationPaymentDate',
    headerName: 'Amortização',
    minWidth: 100,
  },
  {
    field: 'Ações',
    type: 'actions',
    headerName: 'Ações',
    hideable: false,
    sortable: false,
    getActions: (cell) => [
      <EditOfferYieldDescriptionAction
        key='a'
        id={cell.row.id}
        currentYieldDescription={cell.row.yieldDescription}
        showInMenu
      />,
    ],
  },
];

import { ApolloError } from '@apollo/client';
import { INDEXER_OPTIONS } from '@common/constants/INDEXER_OPTIONS';
import { PRODUCT_OPTIONS } from '@common/constants/PRODUCT_OPTIONS';
import { Button } from '@invest-ai/animatrix';
import {
  FormDatePicker,
  FormEasySelect,
  FormPercentageInput,
  FormTextField,
} from '@invest-ai/animatrix';
import { Grid } from '@mui/material';
import { addYears } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  FixedIncomeOfferTarget,
  FixedIncomeProductKind,
  Indexers,
} from '../../../../../../../__generated__/types';
import { TARGET_PUBLIC_OPTIONS } from '../../../../../../common/constants/TARGET_PUBLIC_OPTIONS';
import { floatTransformer } from '../../../../../../common/logic/floatTransformer';
import { useDisplayDealDrawersNavigation } from '../../../../../context/useDisplayDealDrawersNavigation';
import { useCreateFixedIncomeOffer } from '../../../../../hooks/useCreateFixedIncomeOffer';
import { CustomIndexer, IFormData } from '../../../../../interfaces/IFormData';
import { fixValues } from '../../../../../logic/fixValuesFromOfferForm';

export enum ApolloErrorCodes {
  FIXED_INCOME_OFFER_CONFLICT_ERROR = 'FIXED_INCOME_OFFER_CONFLICT_ERROR',
}

export const CreateOfferForm: React.FC = () => {
  const { goBack } = useDisplayDealDrawersNavigation();
  const { createFixedIncomeOffer, loading } = useCreateFixedIncomeOffer();
  const methods = useForm<IFormData>({
    mode: 'onChange',
    defaultValues: {
      indexer: Indexers.PREFIXADO,
      productKind: FixedIncomeProductKind.CDB,
      maturityDate: addYears(new Date(), 1),
      roa: 0.01,
      yieldRate: 0.1,
      indexerPercentRate: 0,
      target: FixedIncomeOfferTarget.QUALIFIED,
      duration: 0,
    },
  });

  const handleError = (err: unknown) => {
    if (err instanceof ApolloError) {
      const firstError = err.graphQLErrors.at(0)?.extensions;
      if (
        firstError?.code === ApolloErrorCodes.FIXED_INCOME_OFFER_CONFLICT_ERROR
      ) {
        toast.error(
          'Já existe outra oferta com as mesmas características que essa.',
        );
      } else {
        toast.error(err.message);
      }
      return;
    }
    throw err;
  };

  const onSubmit = async (data: IFormData) => {
    const fixedValues = fixValues(data);
    try {
      await createFixedIncomeOffer(fixedValues);
      toast.success('Oferta criada com sucesso');
      // TODO: Refetch offers
      goBack();
    } catch (err) {
      handleError(err);
    }
  };

  const indexer = methods.watch('indexer');
  const productKind = methods.watch('productKind');
  const shouldDisplayIndexerPercentRate = indexer === Indexers.CDI;
  const shouldDisplayFreeOfIR =
    productKind === FixedIncomeProductKind.DEBENTURE;

  const shouldDisplayYieldRate = [
    Indexers.IPCA,
    Indexers.PREFIXADO,
    CustomIndexer.CDI_PLUS,
  ].includes(indexer);

  const shouldDisplayDuration = [
    FixedIncomeProductKind.CRI,
    FixedIncomeProductKind.CRA,
    FixedIncomeProductKind.DEBENTURE,
  ].includes(productKind);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormTextField
              name='issuerId'
              control={methods.control}
              label='Banco'
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormTextField
              name='nickname'
              control={methods.control}
              label='Nome do ativo'
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormEasySelect
              name='productKind'
              control={methods.control}
              label='Produto'
              options={PRODUCT_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormEasySelect
              name='indexer'
              control={methods.control}
              label='Indexador'
              options={INDEXER_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            {shouldDisplayYieldRate && (
              <FormPercentageInput
                control={methods.control}
                name='yieldRate'
                label='Taxa de aplicação (%)'
                precision={2}
                rules={{
                  required: true,
                }}
              />
            )}

            {shouldDisplayIndexerPercentRate && (
              <FormPercentageInput
                control={methods.control}
                name='indexerPercentRate'
                label='Taxa de aplicação (%)'
                precision={2}
                rules={{
                  required: true,
                }}
              />
            )}
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormPercentageInput
              control={methods.control}
              name='roa'
              label='ROA (%)'
              precision={2}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormDatePicker
              name='maturityDate'
              control={methods.control}
              label='Data de vencimento'
              rules={{
                required: true,
              }}
            />
          </Grid>

          {shouldDisplayFreeOfIR && (
            <Grid item xs={6} display='flex' flexDirection='column'>
              <FormEasySelect
                control={methods.control}
                name='freeOfIR'
                label='Ativo isento'
                options={[
                  {
                    label: 'Sim',
                    value: 1,
                  },
                  {
                    label: 'Não',
                    value: 0,
                  },
                ]}
                rules={{
                  required: true,
                }}
              />
            </Grid>
          )}

          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormEasySelect
              control={methods.control}
              name='target'
              label='Público Alvo'
              options={TARGET_PUBLIC_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            <FormTextField
              name='rating'
              control={methods.control}
              label='Rating (opcional)'
            />
          </Grid>

          <Grid item xs={6} display='flex' flexDirection='column'>
            {shouldDisplayDuration && (
              <FormTextField
                name='duration'
                control={methods.control}
                label='Duration (em anos)'
                type='number'
                inputProps={{
                  step: '.01',
                }}
                transform={floatTransformer}
                rules={{
                  required: true,
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} justifyContent='flex-end' display='flex'>
            <Button variant='text' onClick={goBack} sx={{ marginRight: 2 }}>
              Cancelar
            </Button>

            <Button
              sx={{ minWidth: 200 }}
              variant='contained'
              color='primary'
              type='submit'
              loading={loading}
              disabled={!methods.formState.isValid}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

import { addMinutes, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parseServerDate } from './parsers';

export const DATE_FORMAT_FOR_PARAM = 'dd/MM/yyyy';
const DATE_TIME_FORMAT_FOR_PARAM = 'dd/MM/yyyy HH:mm:ss';
const DATE_TIME_EXTENDED = "HH:mm, dd 'de' MMMM 'de' yyyy";

export const formatDateTime = (date: Date) =>
  format(
    addMinutes(date, -date.getTimezoneOffset()),
    DATE_TIME_FORMAT_FOR_PARAM,
  );

export const formatDateSubtractTimezone = (date: Date) =>
  format(addMinutes(date, -date.getTimezoneOffset()), DATE_FORMAT_FOR_PARAM);

export const formatDateWithLocale = (date: Date) =>
  format(date, DATE_TIME_EXTENDED, { locale: ptBR });

export const safeFormatDate = (
  date: Date | string | undefined,
  pattern: string = DATE_FORMAT_FOR_PARAM,
): string => {
  if (!date) {
    return '-';
  }
  if (typeof date === 'string') {
    return safeFormatDate(parseServerDate(date), pattern);
  }
  return format(date, pattern, { locale: ptBR });
};

import { Link, Typography } from '@mui/material';
import { MaybeNil } from '../logic/MaybeNil';

export const DetailedInfoLink: React.FC<{ link: MaybeNil<string> }> = ({
  link,
}) => {
  if (!link) return null;
  return (
    <Typography fontSize={12} ml={3} color={'#637381'}>
      Veja mais detalhes dessa emissão{' '}
      <Link target='_blank' href={link}>
        aqui
      </Link>
    </Typography>
  );
};

import { MaybeNil } from '../components/DisplayDeal/logic/MaybeNil';
import { useDealDashboard } from './useDealDashboard';

export const useCalculateDealFromRoute = ({
  offerId,
  positionId,
  duration,
}: {
  offerId: MaybeNil<string>;
  positionId: string;
  duration?: number;
}) => {
  const shouldSkip = !positionId;

  const {
    fixedIncomeOffer,
    fixedIncomePosition,
    dealFinancials,
    customPitch,
    error,
    loading,
    refetch,
  } = useDealDashboard(
    {
      positionId,
      offerId,
      duration,
    },
    {
      skip: shouldSkip,
    },
  );

  return {
    fixedIncomeOffer,
    fixedIncomePosition,
    dealFinancials,
    customPitch,
    error,
    loading,
    refetch,
  };
};

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';

export const EditDealContext = createContainer(() => {
  const query = new URLSearchParams(useLocation().search);
  const durationParam = Number(query.get('d')) || undefined;

  const [visible, setVisible] = useState<boolean>(false);
  const [duration, setDuration] = useState<number | undefined>(durationParam);

  const toggleVisibility = () => setVisible((s) => !s);

  useEffect(() => {
    if (!durationParam) return;
    setDuration(durationParam);
  }, [durationParam]);

  return {
    duration,
    setDuration,
    visible,
    setVisible,
    toggleVisibility,
  };
});

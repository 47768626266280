import { ProductTypes } from '@common/enum/ProductTypes';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { AvailableOfferIndexers } from '../constants/AVAILABLE_OFFER_INDEXERS';
import {
  getYieldFilterConfig,
  yieldFilterConfigMap,
} from '../logic/getYieldFilterConfig';

const DEFAULT_INDEXER = AvailableOfferIndexers.ALL;
const DEFAULT_PRODUCT_TYPE = ProductTypes.ALL;

export const useAvailableOffersFilters = () => {
  const [ratings, setRatings] = useState<string[]>([]);
  const [productType, setProductType] = useState(DEFAULT_PRODUCT_TYPE);
  const [minimumROA, setMinimumROA] = useState(0);
  const [maturityDateRange, setMaturityDateRange] = useState<[number, number]>([
    1, 300,
  ]);

  const [minimumYieldRate, setMinimumYieldRate] = useState(
    yieldFilterConfigMap[DEFAULT_INDEXER].min,
  );
  const [indexer, setIndexer] =
    useState<AvailableOfferIndexers>(DEFAULT_INDEXER);
  const [onlyInvestorQualified, setOnlyInvestorQualified] = useState(false);
  const [onlyFreeOfIR, setOnlyFreeOfIR] = useState(false);

  const [maturityDateRangeLowerBoundary, setMaturityDateRangeLowerBoundary] =
    useState<number>(1);

  useEffect(() => {
    setMaturityDateRange(([min, max]) => [
      Math.max(min, maturityDateRangeLowerBoundary),
      max,
    ]);
  }, [maturityDateRangeLowerBoundary]);

  function changeIndexer(index: AvailableOfferIndexers) {
    setIndexer(index);

    const yieldFilterConfig = getYieldFilterConfig(index);
    setMinimumYieldRate(yieldFilterConfig.min);
  }

  return {
    productType,
    minimumROA,
    maturityDateRange,
    minimumYieldRate,
    indexer,
    maturityDateRangeLowerBoundary,
    onlyInvestorQualified,
    onlyFreeOfIR,
    setProductType,
    setMinimumROA,
    setMaturityDateRange,
    setMinimumYieldRate,
    changeIndexer,
    setMaturityDateRangeLowerBoundary,
    setOnlyInvestorQualified,
    setOnlyFreeOfIR,
    ratings,
    setRatings,
  };
};

export const AvailableOffersFiltersContext = createContainer(
  useAvailableOffersFilters,
);

import { MaybeNil } from 'src/modules/deals/components/DisplayDeal/logic/MaybeNil';
import {
  CalculatePositionFinancialsInput,
  FixedIncomePositionFilter,
  FixedIncomePositionsToDealQueryVariables,
  YieldDescription,
} from '../../../../../__generated__/types';
import { IPaginationParams } from '../interfaces/IPaginationParams';
import { ISortParams } from '../interfaces/ISortParams';
import { FINANCIAL_PARAMS_EMPTY } from './FINANCIAL_PARAMS_EMPTY';

export const getFixedIncomePositionsToDealVariables = (
  search: string,
  pagination: IPaginationParams,
  sort: ISortParams | undefined,
  filter: FixedIncomePositionFilter | undefined,
  comparatorParams?: {
    offerId: string;
    quantity: number;
    offerCustomYieldDescriptionMap: Record<string, YieldDescription>;
  },
): FixedIncomePositionsToDealQueryVariables => {
  const customYieldDescription = comparatorParams?.offerId
    ? comparatorParams?.offerCustomYieldDescriptionMap?.[
        comparatorParams?.offerId
      ]
    : null;

  const financialParamsShorterDuration: MaybeNil<CalculatePositionFinancialsInput> =
    comparatorParams
      ? {
          offerId: comparatorParams.offerId,
          quantity: comparatorParams.quantity,
          offerYieldDescription: customYieldDescription,
        }
      : FINANCIAL_PARAMS_EMPTY;

  const financialParamsLongerDuration: MaybeNil<CalculatePositionFinancialsInput> =
    comparatorParams
      ? {
          offerId: comparatorParams.offerId,
          quantity: comparatorParams.quantity,
          offerYieldDescription: customYieldDescription,
          applyLongerDuration: true,
        }
      : FINANCIAL_PARAMS_EMPTY;
  const withFinancials = !!comparatorParams?.offerId;

  return {
    page: (pagination.page ?? 0) + 1,
    first: pagination.first,
    sortBy: sort?.field,
    sortDirection: sort?.direction,
    search,
    financialParamsShorterDuration,
    financialParamsLongerDuration,
    filter,
    // Local variables
    withFinancials,
  };
};

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { IOfferDrawersNavigationHook } from '../../../../context/IOfferDrawersNavigationHook';
import { useFixedIncomeOfferById } from '../../../../hooks/useFixedIncomeOfferById';
import { EditOfferForm } from './EditOfferForm';
import { getFormOfferFromOffer } from './getFormOfferFromOffer';

export const EditOfferDrawer: React.FC<{
  navigation: IOfferDrawersNavigationHook;
}> = ({ navigation }) => {
  const { editOfferId, isEditOfferDrawerOpen, closeDrawer, goBack } =
    navigation();

  const {
    fixedIncomeOffer,
    loading,
    refetch: refetchOfferEdited,
  } = useFixedIncomeOfferById(editOfferId, {
    skip: !editOfferId,
  });

  async function onSuccess() {
    await refetchOfferEdited();
    goBack();
  }

  const formOffer = getFormOfferFromOffer(fixedIncomeOffer);

  return (
    <Drawer anchor={'right'} open={isEditOfferDrawerOpen} onClose={closeDrawer}>
      <Box
        sx={{
          width: 738,
          paddingRight: 4,
          paddingLeft: 4,
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' flexDirection='row' alignItems='center'>
            <IconButton onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
            <Box>
              <Typography variant='h4'>Editar oferta</Typography>
            </Box>
          </Box>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        {!loading && editOfferId && (
          <EditOfferForm
            offerId={editOfferId}
            values={formOffer}
            onSuccess={onSuccess}
            onCancel={goBack}
            onDelete={goBack}
          />
        )}
      </Box>
    </Drawer>
  );
};

import { Page } from '@invest-ai/animatrix';
import { Container } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { FixedIncomePositionsListParamsContext } from 'src/modules/fixed-income-positions/components/FixedIncomePositionsList/hooks/useFixedIncomePositionsListParams';
import { DisplayDeal } from '../../components/DisplayDeal';
import { EditDealContext } from '../../components/EditDeal/EditDealContext';
import { VisualizationContext } from './VisualizationContext';

export const PublicDealPage: React.FC<{
  isCustomer: boolean;
}> = ({ isCustomer }) => {
  const params = useParams<{
    positionId: string;
    offerId: string;
  }>();
  const [searchParams] = useSearchParams();
  const positionId = params.positionId as string;
  const offerId = params.offerId as string;
  const officeId = searchParams.get('o');

  return (
    <Page title='InvestAI Carteira+ | Negociação'>
      <VisualizationContext.Provider
        initialState={{
          isPublic: true,
          hideSensitiveInformation: isCustomer,
        }}
      >
        <FixedIncomePositionsListParamsContext.Provider>
          <EditDealContext.Provider>
            <Container maxWidth={'xl'}>
              <DisplayDeal
                positionId={positionId}
                offerId={offerId}
                officeId={officeId}
                isCustomer={isCustomer}
              />
            </Container>
          </EditDealContext.Provider>
        </FixedIncomePositionsListParamsContext.Provider>
      </VisualizationContext.Provider>
    </Page>
  );
};

import { Box, Drawer } from '@mui/material';
import { AvailableOffersFiltersContext } from '../../../../context/AvailableOffersFiltersContext';
import { IOfferDrawersNavigationHook } from '../../../../context/IOfferDrawersNavigationHook';
import { FixedIncomeOffers } from './FixedIncomeOffers';

export const AvailableOffersDrawer: React.FC<{
  navigation: IOfferDrawersNavigationHook;
  positionIdToCompare?: string;
}> = ({ navigation, positionIdToCompare }) => {
  const {
    isAvailableOffersDrawerOpen,
    closeDrawer,
    onSelectOfferId,
    openCreateOfferDrawer,
    openEditOfferDrawer,
  } = navigation();

  return (
    <AvailableOffersFiltersContext.Provider>
      <Drawer
        anchor={'bottom'}
        open={isAvailableOffersDrawerOpen}
        onClose={closeDrawer}
      >
        <Box sx={{ p: 2 }}>
          <FixedIncomeOffers
            positionIdToCompare={positionIdToCompare}
            onSelectFixedIncomeOffer={onSelectOfferId}
            onClickCreateOffer={openCreateOfferDrawer}
            onClickEditOffer={openEditOfferDrawer}
          />
        </Box>
      </Drawer>
    </AvailableOffersFiltersContext.Provider>
  );
};

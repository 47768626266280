import { FixedIncomePositionFilter } from 'src/__generated__/types';
import { isArrayFilterActive } from './isArrayFilterActive';
import { isFloatIntervalFilterActive } from './isFloatIntervalFilterActive';

export const countFilters = (filterParams: FixedIncomePositionFilter) => {
  const {
    advisorIds,
    brokerAccountIds,
    creationModes,
    indexers,
    productKinds,
    currentPriceInterval,
    maturityDateInterval,
  } = filterParams;

  let count = 0;
  if (isArrayFilterActive(advisorIds)) {
    count++;
  }
  if (isArrayFilterActive(brokerAccountIds)) {
    count++;
  }
  if (isArrayFilterActive(creationModes)) {
    count++;
  }
  if (isArrayFilterActive(indexers)) {
    count++;
  }
  if (isArrayFilterActive(productKinds)) {
    count++;
  }
  if (isFloatIntervalFilterActive(currentPriceInterval, 0)) {
    count++;
  }

  if (isFloatIntervalFilterActive(maturityDateInterval, 1)) {
    count++;
  }
  return count;
};

import { Page } from '@invest-ai/animatrix';
import { Container } from '@mui/material';
import React from 'react';
import { PositionDrawer } from '../../../custom-position/components/PositionDrawer';
import { FixedIncomePositionsList } from '../../../fixed-income-positions/components/FixedIncomePositionsList';
import { positionsListColumns } from '../../components/FixedIncomePositionsList/column-definitions/positionsListColumns';
import {
  FixedIncomePositionsListParamsContext,
  LIST_POSITIONS_FILTER_KEY,
} from '../../components/FixedIncomePositionsList/hooks/useFixedIncomePositionsListParams';
import { MGMBanner } from './MGMBanner';

export const ListPositionsPage: React.FC = () => {
  return (
    <Page title='InvestAI Carteira+ | Minha Carteira'>
      <Container maxWidth={'xl'}>
        <MGMBanner />
        <FixedIncomePositionsListParamsContext.Provider
          initialState={{
            localStorageFilterKey: LIST_POSITIONS_FILTER_KEY,
          }}
        >
          <FixedIncomePositionsList
            columnsToRender={positionsListColumns}
            baseUrl='/'
          />
          <PositionDrawer baseUrl='/' />
        </FixedIncomePositionsListParamsContext.Provider>
      </Container>
    </Page>
  );
};
